import config from '../config/config'
import { axios } from './index'

const dataPermissionUrl = config.baseUrl + '/user/api/v2/data/permission'

export default {
  //查询角色可配置的数据权限
  getPermissionType(payload) {
    return axios.get(`${dataPermissionUrl}/can/config?roleId=${payload}`)
  },
  //获取权限数据列表
  getData(payload) {
    return axios.get(`${dataPermissionUrl}/search/data?type=${payload.type}&searchContent=${payload.searchContent}`)
  },
  //获取角色对应的数据权限
  getPermissionByRole(payload) {
    return axios.get(`${dataPermissionUrl}/get?roleId=${payload.roleId}&dataPermissionType=${payload.permissionType}`)
  },
  //修改角色的数据权限
  modifyPermission(payload) {
    return axios.post(`${dataPermissionUrl}/modify`, payload)
  }
}
