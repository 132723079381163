<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
        <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过角色名称模糊搜索" class="yt-search" @keydown.enter.native="reSearchRole">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchRole" />
        </el-input>
        <el-button type="primary" size="small" @click="toCreate">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p>新建角色</p>
          </div>
        </el-button>
        <el-button size="small" @click="toDelete(false)">
          <div>
            <YTIcon :href="'#icon-shanchu'" />
            <p>删除角色</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <el-table
          ref="rolePermissionTable"
          :data="rolePermissionList"
          v-loading="rolePermissionLoading"
          element-loading-text="加载中"
          class="yt-table "
          height="calc(100% - 35px)"
          @selection-change="selectRole"
        >
          <el-table-column type="selection" width="31" />
          <el-table-column label="角色名称" prop="roleName" show-overflow-tooltip />
          <el-table-column label="功能权限" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.sysPermissionName !== null && scope.row.sysPermissionName.length > 0" class="tag-wrapper">
                <div class="tag-item system-item">{{ scope.row.sysPermissionName[0] }}</div>
                <el-popover
                  v-if="scope.row.sysPermissionName.length > 1"
                  placement="top"
                  title="功能权限"
                  width="184"
                  trigger="hover"
                  popper-class="yt-table-tag-popover"
                >
                  <div class="tag-list">
                    <div v-for="(item, index) in scope.row.sysPermissionName" :key="index" class="tag-item system-item">
                      {{ item }}
                    </div>
                  </div>
                  <div slot="reference" class="dots">
                    <YTIcon class="system-icon" :href="'#icon-gengduo1'"></YTIcon>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数据权限" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.dataPermissionName !== null && scope.row.dataPermissionName.length > 0" class="tag-wrapper">
                <div class="tag-item">{{ scope.row.dataPermissionName[0] }}</div>
                <el-popover
                  v-if="scope.row.dataPermissionName.length > 1"
                  placement="top"
                  title="数据权限"
                  width="184"
                  trigger="hover"
                  popper-class="yt-table-tag-popover"
                >
                  <div class="tag-list">
                    <div v-for="(item, index) in scope.row.dataPermissionName" :key="index" class="tag-item">
                      {{ item }}
                    </div>
                  </div>
                  <div slot="reference" class="dots">
                    <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="210px">
            <template slot-scope="scope">
              <span class="tool-button" @click="toConfig(scope.row, true)">配置功能权限</span>
              <span class="tool-button" @click="toConfig(scope.row, false)">配置数据权限</span>
              <el-dropdown placement="bottom-start" @command="handleCommand($event, scope.row)">
                <span class="tool-button">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="update">修改</el-dropdown-item>
                  <el-dropdown-item command="delete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!rolePermissionLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          show-elevator
          show-sizer
          show-total
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
    <RoleCUDialog ref="cuDialog" @refresh="searchRole"></RoleCUDialog>
    <ConfirmDialog ref="deleteDialog" :title="'删除角色'" @on-ok="deleteRole">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该角色，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <RolePermissionConfigDialog ref="configDialog" @refresh="searchRole"></RolePermissionConfigDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import RoleCUDialog from '@components/common/dialog/RoleCUDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import RolePermissionConfigDialog from '@components/common/dialog/RolePermissionConfigDialog'
import rolePermissionApi from '@api/rolePermission'
export default {
  name: 'RolePermissionList',
  components: { YTIcon, RoleCUDialog, ConfirmDialog, RolePermissionConfigDialog },
  data() {
    return {
      keyword: '',
      total: 0,
      pageNum: 0,
      pageSize: 10,
      rolePermissionList: [],
      rolePermissionLoading: false,
      roleSelection: [] //选择的角色
    }
  },
  mounted() {
    this.searchRole()
  },
  methods: {
    searchRole() {
      this.rolePermissionLoading = true
      rolePermissionApi.search(this.keyword, this.pageNum, this.pageSize).then(res => {
        if (res.res.total > 0 && Math.ceil(res.res.total / this.pageSize) < this.pageNum + 1) {
          this.pageNum = Math.ceil(res.res.total / this.pageSize) - 1
          this.searchRole()
        } else {
          this.rolePermissionList = res.res.data
          this.total = res.res.total
          this.rolePermissionLoading = false
        }
      })
    },
    reSearchRole() {
      this.pageNum = 0
      this.searchRole()
    },
    toCreate() {
      this.$refs.cuDialog.open(true)
    },
    toDelete(data) {
      if (data) {
        this.roleSelection = [data.roleId]
      } else {
        if (this.roleSelection.length === 0) {
          this.$message.warning('请先勾选要删除的角色')
          return
        }
      }
      this.$refs.deleteDialog.open()
    },
    deleteRole() {
      rolePermissionApi.delete(this.roleSelection).then(res => {
        if (res.res === true) {
          this.$message.success('删除成功')
          this.searchRole()
        }
      })
    },
    toUpdate(row) {
      this.$nextTick(() => {
        this.$refs.cuDialog.initData(row)
      })
      this.$refs.cuDialog.open(false)
    },
    toConfig(data, mode) {
      this.$refs.configDialog.open(mode, data)
    },
    selectRole(selection) {
      this.roleSelection = selection.map(item => {
        return item.roleId
      })
    },
    handleCommand(command, row) {
      if (command === 'update') {
        this.toUpdate(row)
      } else if (command === 'delete') {
        this.toDelete(row)
      }
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchRole()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchRole()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
.system-item {
  background-color: #effff4 !important;
  color: #4ce47a !important;
}
.publicity {
  background-color: #f0fffe !important;
  color: #4ce4da !important;
}
.tag-wrapper {
  .system-icon {
    fill: #4ce47a !important;
  }
}
</style>
