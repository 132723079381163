<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建角色' : '修改角色'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" label-width="132px" :rules="ruleValidate" class="yt-form yt-dialog-form">
      <el-form-item label="角色名称" prop="name">
        <el-input placeholder="请输入角色名称" v-model="formData.name" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rolePermissionApi from '@api/rolePermission'
export default {
  name: 'RoleCUDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('角色名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      formData: {
        name: ''
      },
      loading: false,
      ruleValidate: {
        name: [
          { required: true, message: '角色名称不能为空' },
          { max: 30, message: '角色名称长度不能超过30个字符' },
          { min: 1, message: '角色名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    initData(row) {
      this.formData.name = row.roleName
      this.formData.id = row.roleId
    },
    open(mode) {
      this.mode = mode
      this.visible = true
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          rolePermissionApi
            .add(this.formData.name)
            .then(res => {
              if (res.res === true) {
                this.$message.success('新建角色成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          rolePermissionApi
            .update(this.formData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('修改角色成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
